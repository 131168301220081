.summary-row .rmwc-data-table__cell {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}
.rmwc-data-table__cell:first-of-type {
  padding-left: 1rem;
}
.rmwc-data-table__cell {
  padding: 0.75rem;
}
.rmwc-data-table__cell:last-of-type {
  padding-right: 1.5rem;
  text-align: right;
}
.rmwc-data-table__content {
  position: relative;
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 0.875rem;
  background-color: white;
  width: 100%;
}
