.c-num-input {
    display: inline-block;
}
.c-num-input.m-dirty:not(.m-error) {
    background-color: #fff8dcff;
}

.units-reconcile>.c-num-input>div {
    width: 100px;
}
