.mdc-checkbox__background {
  border: 1px solid #8d8d8d;
  font-family: HelveticaNeue-Medium;
  font-size: 12px;
  color: #111111;
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background {
  border-color: var(--mdc-theme-primary, #018786);
  background-color: var(--mdc-theme-primary, #018786);
}

@keyframes mdc-checkbox-fade-out-background-u5n97oh {
  0%,
  80% {
    border-color: #018786;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #018786);
    background-color: #018786;
    /* @alternate */
    background-color: var(--mdc-theme-primary, #018786);
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent;
  }
}

.checkboxCell {
  width: 20px;
  padding: 0;
}

@media only screen and (max-width: 500px) {
  .productNameCell {
    max-width: 170px;
  }
  .sizeCell {
    max-width: 25px;
  }
  .unitsCell {
    max-width: 70px;
  }
}
