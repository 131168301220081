.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
  display: block;
  font-size: 14px;
}

.mdc-list-item__secondary-text {
  font-size: 14px;
  display: block;
}

.rmwc-data-table__row:first-child .rmwc-data-table__cell {
  border-top: 0;
}

.mdc-text-field__input {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}