.mdc-fab:not(.mdc-fab--extended) {
  width: 40px;
}
.mdc-fab__label {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.mdc-fab {
  height: 40px;
  background-color: #111111;
}

.mdc-fab i * {
  color: white;
}
