.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: var(--mdc-theme-on-surface);
}
.units-select.mdc-ripple-upgraded.units-select.mdc-ripple-surface.mdc-select {
  min-width: 85px;
  max-width: 100px;
}
.mdc-select__native-control,
.mdc-select__selected-text {
  padding-left: 32px;
  font-family: "Helvetica Neue", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  padding-top: 20px;
  padding-bottom: 4px;
  border: none;
  border-bottom: 1px solid;
  outline: none;
  background-color: transparent;
  color: inherit;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.mdc-select__dropdown-icon {
  background-image: url("/icons/ArrowDown.svg");
  background-size: 10px 10px;
  right: 5px;
  top: 7px;
}
i.mdc-select__dropdown-icon {
  background-image: url("/icons/ArrowDown.svg");
  background-size: 10px 10px;
  right: 5px;
  top: 7px;
}
.mdc-select--focused .mdc-select__dropdown-icon {
  background: url("/icons/ArrowDown.svg") no-repeat center;
  left: auto;
  right: 8px;
  position: absolute;
  bottom: 16px;
  width: 24px;
  height: 24px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
}

.mdc-select--focused .mdc-select__dropdown-icon {
  background: url("/icons/ArrowDown.svg") no-repeat center;
  background-size: 10px 10px;
}
.mdc-text-field__input {
  font-family: "'Helvetica Neue Medium', 'Arial', sans-serif";
}
@media only screen and (max-width: 650px) {
  .mdc-select__native-control {
    width: 100px;
  }
}
.mdc-select .mdc-select--outlined {
  height: 95%;
}
.units-select.mdc-select {
  max-width: 100px;
  height: 98%;
}

.rmwc-data-table__cell .mdc-select .mdc-select__native-control {
  padding-bottom: 1.2em;
}

.manualEntrySelect i.mdc-select__dropdown-icon {
  top: 12px;
}
.manualEntrySelect.mdc-select.mdc-select--outlined {
  height: 46px;
}
.manualEntry select.mdc-select__native-control {
  width: 100%;
}
