.checkboxCell,
.checkboxHeadCell {
  width: 25%;
}
.productNameCell,
.productNameHeadCell {
  width: 25%;
}
.sizeCell,
.sizeHeadCell {
  width: 25%;
}
.unitsCell,
.unitsHeadCell {
  width: 25%;
}

/* .transfer-detail-table {
  height: 100vh;
} */
