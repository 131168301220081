.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-on-surface);
}
.mdc-text-field .mdc-text-field__icon {
  margin: -4px;
  font-size: 22px;
  outline: 0;
  box-shadow: none;
}
.mdc-text-field:not(.mdc-text-field--disabled)
  + .mdc-text-field-helper-line
  .mdc-text-field-helper-text {
  color: #111111;
}

.mdc-text-field:not(.mdc-text-field--disabled)
  .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled)
  + .mdc-text-field-helper-line
  .mdc-text-field-character-counter {
  color: #111111;
}

.mdc-ripple-upgraded
  .mdc-ripple-surface
  .mdc-text-field
  .mdc-text-field--upgraded
  .mdc-text-field--textarea
  .mdc-text-field--fullwidth
  .mdc-text-field--outlined {
  border-color: #111111;
}
