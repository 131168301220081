@font-face {
  font-family: "Nike Glyphs";
  src: url("./fonts/nike-glyphs.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue Regular";
  src: url("./fonts/helvetica-neue-regular.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Neue Medium"),
    url("./fonts/HelveticaNeue-Medium.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Neue"), url("./fonts/HelveticaNeue.woff") format("woff");
}
html {
  height: 100%;
  padding: constant(safe-area-inset-top) constant(safe-area-inset-right)
    constant(safe-area-inset-bottom) constant(safe-area-inset-left);
}
body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
#root {
  height: 100%;
}
@media screen and (min-width: 600px) {
  .camera {
    top: 64px;
    position: fixed;
    left: 0;
    right: 0;
    /* flexbox camera portion occupies 2/6ths of non-top-app-bar portion */
    height: calc((100% - 64px) * (2 / 6));
  }
  .topAppBarHeight {
    height: 64px;
  }
  .heightMinusTopAppBar {
    height: calc(100% - 64px);
  }
}
@media screen and (max-width: 599px) {
  .camera {
    top: 56px;
    position: fixed;
    left: 0;
    right: 0;
    /* flexbox camera portion occupies 2/6ths of non-top-app-bar portion */
    height: calc((100% - 56px) * (2 / 6));
  }
  .topAppBarHeight {
    height: 56px;
  }
  .heightMinusTopAppBar {
    height: calc(100% - 56px);
  }
}

#interactive {
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  visibility: hidden;
}
#interactive.active {
  opacity: 1;
  height: 100%;
  visibility: visible;
}
#interactive video {
  width: 100%;
  /* height: 100%; */
}
.rmwc-data-table__head-cell {
  position: relative;
  vertical-align: bottom;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: 0;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  box-sizing: border-box;
  height: 3rem;
  color: var(--mdc-theme-secondary-on-background, rgba(0, 0, 0, 0.54));
  background-color: var(--mdc-theme-surface, white);
}

.checkboxCell > .rmwc-data-table__cell {
  margin-right: 15px;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: rgba(0, 0, 0, 1) !important;
}

td.unitsCell.rmwc-data-table__cell {
  width: 1000%;
}

.mdc-text-field:not(.mdc-text-field--disabled)
  + .mdc-text-field-helper-line
  .mdc-text-field-helper-text {
  display: none;
}

.addRemoveWrap
  .rmwc-data-table.rmwc-data-table--sticky-rows.rmwc-data-table--sticky-rows-1 {
  overflow-x: hidden;
  padding-bottom: 72px;
  overflow-y: visible;
  height: auto;
}

@media screen and (min-height: 599.98px) {
  .addRemoveWrap .rmwc-data-table.rmwc-data-table--sticky-rows.rmwc-data-table--sticky-rows-1 {
    overflow-y: auto;
    height: calc(46vh - 72px);
  }
}

.terms-dialog .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title, .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
@media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
  @media screen and (max-width: 599px) {
    /*
      Define here the CSS styles applied only to Safari browsers
      (any version and any device)
     */
    .addRemoveWrap
      .rmwc-data-table.rmwc-data-table--sticky-rows.rmwc-data-table--sticky-rows-1 {
      overflow-y: scroll;
      height: calc(100% - 60px);
      max-height: 46vh;
      overflow-x: hidden;
    }
  }
  table.rmwc-data-table__content {
    width: 100% !important;
  }
  .rmwc-data-table__content {
    width: 100%;
  }
  .addRemoveWrap
    .rmwc-data-table.rmwc-data-table--sticky-rows.rmwc-data-table--sticky-rows-1 {
    height: calc(50vh - 26px);
  }
  .manualEntry {
    max-height: 95px;
  }
}
