:root {
  --mdc-typography-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  --mdc-theme-primary-on-background: "#111111";
  --mdc-theme-error: #fe0000;
}

.mdc-typography--headline5 {
  font-size: 18px !important;
  font-family: "Helvetica Neue Medium", Helvetica, Arial, sans-serif !important;
  color: #111111;
}

.mdc-typography--headline3 {
  font-size: 24px !important;
  font-family: "Helvetica Neue Medium", Helvetica, Arial, sans-serif !important;
  color: #111111;
}
.mdc-fab .mdc-fab__icon {
  font-weight: 800;
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #fe0000;
}

.mdc-text-field__input {
  font-family: "'Helvetica Neue', 'Arial', sans-serif";
}
