.mdc-text-field--fullwidth.mdc-text-field--textarea .mdc-text-field__input {
  resize: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.dispute-dialog .mdc-dialog .mdc-dialog__content {
  font-family: var(--mdc-typography-font-family);
  letter-spacing: 1;
  font-size: 16px;
  color: #111111;
  text-align: left;
  line-height: 20px;
}

.dispute-dialog .mdc-text-field-helper-line {
  display: none;
}

.dispute-dialog .mdc-dialog__title .mdc-typography--headline3 {
  margin-top: 0;
}
.units-reconcile .mdc-select__native-control,
.mdc-select__selected-text {
  background-color: var(--mdc-theme-on-primary);
  height: 46px;
}

.units-reconcile .mdc-select {
  height: 46px;
}

.units-reconcile .units-select .mdc-select__native-control,
.mdc-select__selected-text {
  padding-top: 3px;
}

.units-reconcile
  .units-select
  .mdc-ripple-upgraded
  .units-select
  .mdc-ripple-surface
  .mdc-select,
.mdc-select:not(.mdc-select--disabled) {
  background-color: var(--mdc-theme-on-primary);
}

.units-reconcile
  .units-select
  .mdc-ripple-upgraded
  .units-select
  .mdc-ripple-surface
  .mdc-select {
  background-color: var(--mdc-theme-on-primary);
}

.units-reconcile .mdc-select::before,
.mdc-select::after {
  background-color: var(--mdc-theme-on-primary);
}

.units-reconcile .mdc-select__native-control .mdc-select__selected-text {
  padding: 0 0 0 0;
}

.units-reconcile .mdc-select__native-control {
  padding-left: 10px;
  padding-right: 0;
}

.units-reconcile .mdc-select,
.mdc-select__native-control {
  border-radius: 0;
}

.units-reconcile .mdc-select {
  width: 65px;
}

.units-reconcile .mdc-line-ripple,
.mdc-line-ripple .mdc-line-ripple-active {
  display: none;
}

.units-reconcile .mdc-select__dropdown-icon {
  background-image: url("/icons/ArrowDown.svg");
  background-size: 10px 10px;
  bottom: 10px;
  right: 5px;
}

/* .units-reconcile .mdc-select--focused .mdc-select__dropdown-icon {
  background-image: url("/icons/ArrowUp.svg");
  background-size: 10px 10px;
  bottom: 10px;
  right: 5px;
} */
