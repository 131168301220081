.c-page-layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.c-page-layout .rmwc-data-table {
    box-sizing: border-box;
}

@media screen and (max-height: 599.98px) {
    .c-page-layout {
        overflow-y: auto;
        overflow-x: hidden;
    }
}
