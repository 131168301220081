@media (max-width: 599px) {
  .closet-total-sticky {
    top: 56px !important;
  }

  .closet-table-headers-sticky {
    top: 87.45px !important;
  }
}
.closet-total-sticky {
  position: fixed;
  /* height: 70px; */
  top: 64px;
  width: 100%;
  z-index: 4;
  background-color: white;
}

.closet-table-headers-sticky {
  position: fixed;
  top: 96.45px;
  width: 100%;
  z-index: 4;
  border-top: 1px solid #dddddd;
}

.closet-table-sticky {
  margin-top: 40px;
}
