.mdc-select {
  width: 100%;
}

.data-table {
  border-top: none;
  width: 100%;
  height: calc(100% - 60px);
}

.data-table-content {
  width: 100%;
}
